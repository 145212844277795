// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-metrics-tsx": () => import("./../../../src/pages/metrics.tsx" /* webpackChunkName: "component---src-pages-metrics-tsx" */),
  "component---src-pages-results-tsx": () => import("./../../../src/pages/results.tsx" /* webpackChunkName: "component---src-pages-results-tsx" */)
}

